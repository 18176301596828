import React, { useState } from 'react';
import '../../css/CampaignRequestView.css';


const CampaignRequestViewComponent = ({ campaignRequest }) => {
  const [activeTab, setActiveTab] = useState('main');

  // Render the tabs
  const renderTabs = () => {
    return (
      <div>
        <button onClick={() => setActiveTab('main')}>Main</button>
        <button onClick={() => setActiveTab('details')}>Details</button>
        {campaignRequest.eventAddress && (
          <button onClick={() => setActiveTab('event')}>Event Information</button>
        )}
        {campaignRequest.rsvp && (
          <button onClick={() => setActiveTab('rsvp')}>RSVP Information</button>
        )}
      </div>
    );
  };

  const LabelValueComponent = ({label, value}) => ( value &&
    <tr>
    <td style={{ fontWeight: 'bold' }}>{label}</td>
    <td>{value}</td>
  </tr>
  );

  const CampaignRequestHeader = ({ campaignRequest }) => (
    <table>
      <tbody>
      <LabelValueComponent label="Campaign ID" value={campaignRequest.id}/>
      <LabelValueComponent label="Campaign Name" value={campaignRequest.name}/>
      <LabelValueComponent label="Campaign Requester" value={campaignRequest.requesterName}/>
      <LabelValueComponent label="Campaign Requester Email" value={campaignRequest.requesterEmail}/>
      <LabelValueComponent label="Campaign Type" value={campaignRequest.campaignType}/>
      <LabelValueComponent label="Created Date" value={campaignRequest.created}/>
      </tbody>
    </table>
  );

  const CampaignRequestDetail = ({ campaignRequestDetail }) => (
    <table>
      <tbody>
      <LabelValueComponent label="Audience" value={campaignRequestDetail.audience}/>
      <LabelValueComponent label="Seedlist" value={campaignRequestDetail.seedlist}/>
      <LabelValueComponent label="Subject Line" value={campaignRequestDetail.subjectLine}/>
      <LabelValueComponent label="Approval List" value={campaignRequestDetail.approvalList}/>
      <LabelValueComponent label="CTA Link" value={campaignRequestDetail.ctaLink}/>
      <LabelValueComponent label="Goals" value={campaignRequestDetail.goals}/>
      <LabelValueComponent label="Has Legal Sign Off?" value={campaignRequestDetail.hasLegalSignoff ? "Yes" : "No"}/>
      <LabelValueComponent label="Has Awards and Accolades?" value={campaignRequestDetail.hasAwardsAndAccolades ? "Yes" : "No"}/>
      </tbody>
    </table>
  );

  
  const CampaignRequestEventLocation = ({ campaignRequestEventLocation }) => (
    <table>
      <tbody>
      <LabelValueComponent label="Venue Name" value={campaignRequestEventLocation.venueName}/>
      <LabelValueComponent label="Venue Address 1" value={campaignRequestEventLocation.venueAddress1}/>
      <LabelValueComponent label="Venue Address 2" value={campaignRequestEventLocation.venueAddress2}/>
      <LabelValueComponent label="Venue City" value={campaignRequestEventLocation.venueCity}/>
      <LabelValueComponent label="Venue State" value={campaignRequestEventLocation.venueState}/>
      <LabelValueComponent label="Venue Zip" value={campaignRequestEventLocation.venueZip}/>
      </tbody>
    </table>
  );


  // Render the active tab
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'main':
        return <div><CampaignRequestHeader campaignRequest={campaignRequest}/></div>;
      case 'details':
        return <div><CampaignRequestDetail campaignRequestDetail={campaignRequest.campaignDetail} /></div>;
      case 'event':
        return <div><CampaignRequestEventLocation campaignRequestEventLocation={campaignRequest.eventAddress}/></div>;
      case 'rsvp':
        return <div>RSVP details view</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderTabs()}
      {renderActiveTab()}
    </div>
  );
};

export default CampaignRequestViewComponent;
