import {
    Table, Flex,
    TableBody,
    TableCell,
    TableHead,
    TableRow, useAuthenticator, View, Heading, Button, useTheme, TextField, SelectField,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { useParams, useNavigate } from "react-router-dom";
//import { createPhotoPost as createPhotoPostMutation } from '../graphql/customMutations';
import { findCampaignRequest } from '../../graphql/queries';
import ChatWindow from './ChatComponent';
import CampaignRequestViewComponent from  './CampaignRequestViewComponent';
import { usePromiseTracker } from "react-promise-tracker";
import { FidgetSpinner } from 'react-loader-spinner';




export function ProtectedCampaignRequestView() {
    const { route } = useAuthenticator((context) => [context.route]);
    const message =
        route === 'authenticated' ? DisplayCampaignRequest() : 'Loading...';
    return <View level={1}>{message}</View>;
}

const initialFormState = { title: '' }




const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (promiseInProgress &&
        <TableRow textAlign="center">
            <TableCell key="loading" colSpan="100%" as="th">      <FidgetSpinner type="ThreeDots" backgroundColor="#004890" ballColors={["gray", "gray", "gray"]} height="100" width="100" />
            </TableCell>
        </TableRow>
    );
}



function DisplayCampaignRequest() {

    const [campaignRequest, setCampaignRequest] = useState([]);
    const params = useParams();



          

    useEffect(() => {
        fetchCampaignRequest();
      document.title = 'MST Campaign Request';  

      }, []);

    async function fetchCampaignRequest() {
        if (params.id) {
          const apiData = await API.graphql({ query: findCampaignRequest, variables: { id: params.id } });
    
          setCampaignRequest(apiData.data.findCampaignRequest);
        }
      }

    
    //dynamic display condition
    if (1 == 2) {
        return (<View>Unknown Error</View>)
    }

    return (
        <View>
            <Flex direction="row" justifyContent="center">
                <Heading level={3}>Campaign Request View</Heading>
            </Flex>
            <Flex>
                <CampaignRequestViewComponent campaignRequest={campaignRequest}/>
                </Flex><Flex>
                <ChatWindow campaignRequestId={params.id}/>
                </Flex>
        </View>
    )
} 