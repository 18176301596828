import {
    Table, Flex,
    TableBody,
    TableCell,
    TableHead,
    TableRow, useAuthenticator, View, Heading, Button, useTheme,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useParams, useNavigate } from "react-router-dom";
//import { createPhotoPost as createPhotoPostMutation } from '../graphql/customMutations';
import { listLatestCampaignRequestsByField as listLatestCampaignRequests } from '../../graphql/queries';
import { trackPromise } from 'react-promise-tracker';



export function ProtectedCreateCampaignRequest() {
    const { route } = useAuthenticator((context) => [context.route]);
    const message =
        route === 'authenticated' ? CreateCampaignRequest() : 'Loading...';
    return <View level={1}>{message}</View>;
}

const initialFormState = { title: '' }

const RenderCampaignRequestTable = (campaignTableData, campaignTableDataError) => {
    const { tokens } = useTheme();
    return class extends React.Component {
        render() {
            return (
                <Table highlightOnHover={true}>
                    <TableHead>
                        <TableRow>                            
                            <TableCell as="th">&nbsp;</TableCell>
                            <TableCell as="th">Campaign Name</TableCell>
                            <TableCell as="th">Requester Name</TableCell>
                            <TableCell as="th">Requester Email</TableCell>
                            <TableCell as="th">Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaignTableData.map(campReq => (
                            <TableRow key={campReq.id}>
                                <TableCell  maxWidth="5px"><Button backgroundColor={tokens.colors.blue[10]}>View</Button></TableCell>
                                <TableCell>{campReq.name}</TableCell>
                                <TableCell>{campReq.requesterName}</TableCell>
                                <TableCell>{campReq.requesterEmail}</TableCell>
                                <TableCell>{campReq.created}</TableCell>
                            </TableRow>
                        ))}
                        {<TableRow key="error"><TableCell colSpan="100%">{campaignTableDataError}</TableCell></TableRow>}
                    </TableBody>
                </Table>)
        }
    }
}

function CreateCampaignRequest() {
    

    const [campaignTableData, setCampaignTableData] = useState([]);
    const [campaignTableDataError, setCampaignTableDataError] = useState("");
    const { tokens } = useTheme();
    const params = useParams();

    async function fetchCampaignRequests() {
        console.log('I am fetching campaign requests!');
        //const apiData = trackPromise(await API.graphql({ query: listLatestCampaignRequests, variables: { sortDesc: true } }));
        //setCampaignTableData(apiData.data.listLatestCampaignRequests);
        trackPromise(API.graphql({ query: listLatestCampaignRequests, variables: { sortDesc: true } })
            .then((apiData) => { setCampaignTableData(apiData.data.listLatestCampaignRequests) })
            .catch((error) => { setCampaignTableDataError(error.errors[0].message) }));
    }

    useEffect(() => {
        console.log("I Only run once (When the component gets mounted)");
        fetchCampaignRequests();
    }, []);


    
    const CampaignRequestTable = RenderCampaignRequestTable(campaignTableData, campaignTableDataError);

    //dynamic display condition
    if (1 == 2) {
        return (<View>Invalid Photo Album ID</View>)
    }

    return (
        <View>
            <Flex direction="row" justifyContent="center">
                <Heading level={3}>Campaign Request Dashboard</Heading>
            </Flex>
            <Flex direction="row" justifyContent="left">
                <Button backgroundColor={tokens.colors.blue[20]} onClick={fetchCampaignRequests}>Refresh Table</Button>
                <Button backgroundColor={tokens.colors.blue[20]}>Create New Campaign Request</Button>
            </Flex>
            <Flex direction="row" justifyContent="center">
                {campaignTableData.length > 0 && <CampaignRequestTable data={campaignTableData} />}
            </Flex>
        </View>
    )
} 