/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const findCampaignRequest = /* GraphQL */ `
  query FindCampaignRequest($id: ID!) {
    findCampaignRequest(id: $id) {
      id
      name
      created
      campaignType
      requesterName
      requesterEmail
      requestedLaunchDate
      campaignDetail {
        audience
        subjectLine
        seedlist
        approvalList
        ctaLink
        goals
        hasLegalSignoff
        hasAwardsAndAccolades
      }
      rsvpDetail {
        eventName
        ticketType
        ticketRedemptionInst
        extraCADTickets
      }
      eventTimeline {
        firstReminderDate
        secondReminderDate
        rsvpCloseDate
        waitlistCloseDate
      }
      eventAddress {
        venueName
        venueAddress1
        venueAddress2
        venueCity
        venueState
        venueZip
      }
    }
  }
`;
export const listLatestCampaignRequestsByField = /* GraphQL */ `
  query ListLatestCampaignRequests($sortDesc: Boolean) {
    listLatestCampaignRequestsByField(sortDesc: $sortDesc) {
      id
      name
      created
      campaignType
      requesterName
      requesterEmail
      requestedLaunchDate
      campaignDetail {
        audience
        subjectLine
        seedlist
        approvalList
        ctaLink
        goals
        hasLegalSignoff
        hasAwardsAndAccolades
      }
      rsvpDetail {
        eventName
        ticketType
        ticketRedemptionInst
        extraCADTickets
      }
      eventTimeline {
        firstReminderDate
        secondReminderDate
        rsvpCloseDate
        waitlistCloseDate
      }
      eventAddress {
        venueName
        venueAddress1
        venueAddress2
        venueCity
        venueState
        venueZip
      }
    }
  }
`;



export const listLatestCampaignRequests = /* GraphQL */ `
  query ListLatestCampaignRequests($queryByField: String!, $queryByFieldValue: String!, $sortDesc: Boolean) {
    listLatestCampaignRequests(queryByField: $queryByField, queryByFieldValue: $queryByFieldValue, sortDesc: $sortDesc) {
      id
      name
      created
      campaignType
      requesterName
      requesterEmail
      requestedLaunchDate
      campaignDetail {
        audience
        subjectLine
        seedlist
        approvalList
        ctaLink
        goals
        hasLegalSignoff
        hasAwardsAndAccolades
      }
      rsvpDetail {
        eventName
        ticketType
        ticketRedemptionInst
        extraCADTickets
      }
      eventTimeline {
        firstReminderDate
        secondReminderDate
        rsvpCloseDate
        waitlistCloseDate
      }
      eventAddress {
        venueName
        venueAddress1
        venueAddress2
        venueCity
        venueState
        venueZip
      }
    }
  }
`;


export const queryCampaingRequestById = `query QueryCampaingRequestById($id: ID!) {
  findCampaignRequest(id: $id) {
    id
    name
    requestedLaunchDate
    requesterEmail
    requesterName
    campaignType
    created
  }
}`;







export const getCampaignRequestMessagesById = `query getCampaignRequestMessagesById($campaignRequestId: ID!) {
  getCampaignRequestMessages(campaignRequestId: $campaignRequestId) {
    campaignRequestId
    campaignRequestMessages {
      created
      message
      messageId
      type
      username
    }
  }
}`;
