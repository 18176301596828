import React, { useState, useEffect, useLayoutEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { subscribeToCreqMessagesAndAuditItemsById } from '../../graphql/subs';
import { getCampaignRequestMessagesById } from "../../graphql/queries";
import { createCampaignRequestMessage } from "../../graphql/mutations";

const ChatWindow = ({ campaignRequestId }) => {
    const [messages, setMessages] = useState([]);
    const [filter, setFilter] = useState('all');
    const [newMessage, setNewMessage] = useState('');

    // Use the useEffect hook to unsubscribe from the subscription when the component is unmounted
useEffect(() => {

    fetchCampaignRequestMessages();

    const subscription = API.graphql(graphqlOperation(subscribeToCreqMessagesAndAuditItemsById, { campaignRequestId })).subscribe({
        next: (data) => {
            let subscriptionMessages = data.value.data.onCreateMessagesAndAuditItems.campaignRequestMessages;
            // If the subscriptionMessages is not an array, wrap it in an array
            if (!Array.isArray(subscriptionMessages)) {
                subscriptionMessages = [subscriptionMessages];
            }
            // Add the new message(s) to the existing list of messages
            console.log("before subscriptionMessages: " +JSON.stringify(messages));
            //setMessages([...messages, ...subscriptionMessages]);
            setMessages((prevMessages) => [...prevMessages, ...subscriptionMessages]);
            console.log("subscriptionMessages: " + JSON.stringify(subscriptionMessages));
        },
        error: (error) => {
            // handle the error here
            console.error(error);
        },
    });
    
    // Unsubscribe from the subscription when the component is unmounted
    return () => {
        subscription.unsubscribe();
    };
}, []); // Second argument should be an empty array


    async function fetchCampaignRequestMessages() {
        if (campaignRequestId) {
            const apiData = await API.graphql({ query: getCampaignRequestMessagesById, variables: { campaignRequestId } });

            setMessages(apiData.data.getCampaignRequestMessages.campaignRequestMessages);
            console.log("messages: " +JSON.stringify(messages));
        }
    }
    //if(messages.length < 1)
        //fetchCampaignRequestMessages();

    // Function to handle filtering the messages by type
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    // Function to handle updating the value of the new message input field
    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    // Function to handle creating a new message
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Invoke the createCampaignRequestMessage mutation to create the new message
        const result = await API.graphql(
            graphqlOperation(createCampaignRequestMessage, { input: { campaignRequestId: campaignRequestId, message: newMessage, type: "message" } })
        );

        // Clear the value of the new message input field
        setNewMessage('');
    };

    // Filter the messages based on the current filter state
    const sortedMessages = messages.sort((a, b) => new Date(b.created) - new Date(a.created));
    const filteredMessages = sortedMessages.filter((message) => {
        if (filter === 'all') {
            return true;
        } else if (filter === 'messages' && message.type === 'message') {
            return true;
        } else if (filter === 'audit' && message.type === 'audit') {
            return true;
        }
        return false;
    });

    return (
        <div>
            {/* Render a form for submitting a new message */}
            <form onSubmit={handleSubmit}>Add a New Work Note
                <input type="text" value={newMessage} onChange={handleMessageChange} />
                <button type="submit">Send</button>
            </form>
            {/* Render a select element for filtering the messages by type */}Filter by Message Type:
            <select value={filter} onChange={handleFilterChange}>
                <option value="all">All</option>
                <option value="messages">Messages</option>
                <option value="audit">Audit</option>
            </select>

            {/* Render a list of messages */}
            <table border="1">
                <thead>
                    <tr>
                        <th>Message</th>
                        <th>Username</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMessages.map((message) => (
                        <tr key={message.messageId}>
                            <td width="100%">{message.message}</td>
                            <td>{message.username}</td>
                            <td>{message.created}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChatWindow;
