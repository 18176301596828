import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
/*
import { usePromiseTracker } from "react-promise-tracker";
import {FidgetSpinner} from 'react-loader-spinner';
*/
Amplify.configure(config);

/*
const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
     return (promiseInProgress && 
      <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <FidgetSpinner type="ThreeDots" backgroundColor="#004890" ballColors={["gray","gray","gray"]} height="100" width="100" />
    </div>
    );  
   }
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/*<LoadingIndicator/>*/}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//reportWebVitals(console.log);
