//App.js
import { Authenticator } from '@aws-amplify/ui-react';

import { RequireAuth } from './components/RequireAuth';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { Amplify, Auth } from "aws-amplify";
import { ProtectedCampaignRequests as CampaignRequests } from './components/protected/CampaignRequests';
import { ProtectedCreateCampaignRequest as CreateCampaignRequest } from './components/protected/CreateCampaignRequest';
import { ProtectedCampaignRequestView as CampaignRequestView } from './components/protected/CampaignRequestView';
import NoPage from './components/NoPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import {aws_appsync_graphqlEndpoint} from 'aws-export';
import './App.css';

Amplify.configure({
  API: {
    aws_appsync_graphqlEndpoint: "https://jftosyo5xbfhphdflkfkpnfcpa.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: 'us-east-1',aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    graphql_headers: async () => ({
        'Authorization': 'Bearer '+(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken})
},
aws_appsync_region: 'us-east-1',
aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
graphql_headers: async () => ({
    'Authorization': 'Bearer '+(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken})

});

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/campaignRequests" element={<RequireAuth><CampaignRequests/></RequireAuth>} />
          <Route path="/createCampaignRequest" element={<RequireAuth><CreateCampaignRequest/></RequireAuth>} />
          <Route path="/creq/:id" element={<RequireAuth><CampaignRequestView/></RequireAuth>} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;