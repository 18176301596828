

  /* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToCreqMessagesAndAuditItemsById = /* GraphQL */ `
subscription subscribeToCreqMessagesAndAuditItems($campaignRequestId: ID!) {
    onCreateMessagesAndAuditItems(campaignRequestId: $campaignRequestId) {
      campaignRequestId
      campaignRequestMessages {
        messageId
        created
        message
        type
        username
      }
    }
  }
`;