import {
    Table, Flex,
    TableBody,
    TableCell,
    TableHead,
    TableRow, useAuthenticator, View, Heading, Button, useTheme, TextField, SelectField,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useParams, useNavigate } from "react-router-dom";
//import { createPhotoPost as createPhotoPostMutation } from '../graphql/customMutations';
import { listLatestCampaignRequestsByField as listLatestCampaignRequests, listLatestCampaignRequests as listLatestCampaignRequestsByField } from '../../graphql/queries';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { FidgetSpinner } from 'react-loader-spinner';



export function ProtectedCampaignRequests() {
    const { route } = useAuthenticator((context) => [context.route]);
    const message =
        route === 'authenticated' ? DisplayCampaignRequests() : 'Loading...';
    return <View level={1}>{message}</View>;
}

const initialFormState = { title: '' }




const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (promiseInProgress &&
        <TableRow textAlign="center">
            <TableCell key="loading" colSpan="100%" as="th">      <FidgetSpinner type="ThreeDots" backgroundColor="#004890" ballColors={["gray", "gray", "gray"]} height="100" width="100" />
            </TableCell>
        </TableRow>
    );
}

function DisplayCampaignRequests() {

    const navigate = useNavigate();
    const [campaignTableData, setCampaignTableData] = useState([]);
    const [campaignTableDataError, setCampaignTableDataError] = useState("");
    const [sortDesc, setSortDesc] = useState(true);
    const [campaignTypeValue, setCampaignTypeValue] = React.useState('');
    const { tokens } = useTheme();
    const params = useParams();

    async function fetchCampaignRequestsSorted() {
        setSortDesc(!sortDesc);
        fetchCampaignRequests();
    }

    const RenderCampaignRequestSearchFields = () => {
        return class extends React.Component {
            render() {
                return (
                    <Flex><TextField placeholder="Create Date" /></Flex>
                )
            }
        }
    }


    const RenderCampaignRequestTable = (campaignTableData, campaignTableDataError) => {
        const { tokens } = useTheme();
        return class extends React.Component {
            render() {
                return (
                    <Table highlightOnHover={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">&nbsp;</TableCell>
                                <TableCell as="th">Campaign Name</TableCell>
                                <TableCell as="th">Requester Name</TableCell>
                                <TableCell as="th">Requester Email</TableCell>
                                <TableCell as="th">Campaign Type</TableCell>
                                <TableCell id="created" onClick={fetchCampaignRequestsSorted} as="th">Created {sortDesc ? "desc" : "asc"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <LoadingIndicator />
                            {campaignTableData.length > 0 && campaignTableData.map(campReq => (
                                <TableRow key={campReq.id}>
                                    <TableCell maxWidth="5px">
                                    
                <Button backgroundColor={tokens.colors.blue[20]} onClick={() => navigate(`/creq/${campReq.id}`)}>View</Button>
                
                </TableCell>
                                    <TableCell>{campReq.name}</TableCell>
                                    <TableCell>{campReq.requesterName}</TableCell>
                                    <TableCell>{campReq.requesterEmail}</TableCell>
                                    <TableCell>{campReq.campaignType}</TableCell>
                                    <TableCell>{campReq.created}</TableCell>
                                </TableRow>
                            ))}
                            {<TableRow key="error"><TableCell colSpan="100%">{campaignTableDataError}</TableCell></TableRow>}
                        </TableBody>
                    </Table>)
            }
        }
    }

    async function fetchCampaignRequests() {
        console.log('I am fetching campaign requests!');
        //const apiData = trackPromise(await API.graphql({ query: listLatestCampaignRequests, variables: { sortDesc: true } }));
        //setCampaignTableData(apiData.data.listLatestCampaignRequests);
        setCampaignTypeValue("");
        setCampaignTableData([]);
        trackPromise(API.graphql({ query: listLatestCampaignRequests, variables: { sortDesc: sortDesc } })
            .then((apiData) => { (apiData.data.listLatestCampaignRequestsByField.length > 0) ? setCampaignTableData(apiData.data.listLatestCampaignRequestsByField) : null() })
            .catch((error) => { (error.errors[0].message != "" && error.errors[0].message) ? setCampaignTableDataError(error.errors[0].message) : null() }));
    }

    async function fetchCampaignRequestsByField(campaignTypeValue) {
        console.log('I am fetching campaign requests!');
        //const apiData = trackPromise(await API.graphql({ query: listLatestCampaignRequests, variables: { sortDesc: true } }));
        //setCampaignTableData(apiData.data.listLatestCampaignRequests);
        setCampaignTableData([]);
        trackPromise(API.graphql({ query: listLatestCampaignRequestsByField, variables: { queryByField: "campaignType", queryByFieldValue: campaignTypeValue, sortDesc: sortDesc } })
            .then((apiData) => { (apiData.data.listLatestCampaignRequests.length > 0) ? setCampaignTableData(apiData.data.listLatestCampaignRequests) : null() })
            .catch((error) => { (error.errors[0].message != "" && error.errors[0].message) ? setCampaignTableDataError(error.errors[0].message) : null() }));
    }

    async function campaignTypeLookup(campaignTypeValue)
    {
        setCampaignTypeValue(campaignTypeValue);
        fetchCampaignRequestsByField(campaignTypeValue);
    }

    useEffect(() => {
        console.log("I Only run once (When the component gets mounted)");
        fetchCampaignRequests();

        return () => console.log("Cleanup..");
    }, []);



    const CampaignRequestTable = RenderCampaignRequestTable(campaignTableData, campaignTableDataError);

    //dynamic display condition
    if (1 == 2) {
        return (<View>Unknown Error</View>)
    }

    return (
        <View>
            <Flex direction="row" justifyContent="center">
                <Heading level={3}>Campaign Request Dashboard</Heading>
            </Flex>
            <Flex>
                {/*<TextField placeholder="Create Date" />*/}
                <SelectField
                label="Campaign Type" value={campaignTypeValue} onChange={(e) => campaignTypeLookup(e.target.value)}
            >
                <option value="">Select One</option>
                <option value="RSVP">RSVP</option>
                <option value="Awareness">Awareness</option>
                <option value="Non-Event">Non-Event</option>
            </SelectField></Flex>
            <Flex direction="row" justifyContent="left">
                <Button backgroundColor={tokens.colors.blue[20]} onClick={fetchCampaignRequests}>Refresh Table</Button>
                <Button backgroundColor={tokens.colors.blue[20]} onClick={() => navigate('/createCampaignRequest')}>Create New Campaign Request</Button>
            </Flex>
            <Flex direction="row" justifyContent="center">
                {<CampaignRequestTable data={campaignTableData} />}
            </Flex>
        </View>
    )
} 